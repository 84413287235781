import { routes } from 'routes';

export const menuItems = [
  {
    label: 'Dokumenty',
    to: routes.home,
  },
  {
    label: 'Formularz kontaktowy',
    to: routes.contactForm,
  },
];

export const menuButtons = [
  {
    label: 'Regulamin naborów',
    file: 'RECRUITMENT_TERMS',
  },
  {
    label: 'Regulamin korzystania ze strony',
    file: 'TERMS_OF_USE_OF_THE_WEBSITE',
  },
  {
    label: 'Deklaracja dostępności',
    file: 'DECLARATION_OF_AVAILABILITY',
  },
  {
    label: 'Informacja o przetwarzaniu danych osobowych',
    file: 'INFORMATION_ON_THE_PROCESSING_OF_PERSONAL_DATA',
  },
  {
    label: 'Polityka prywatności i wykorzystywania plików cookies',
    file: 'PRIVACY_POLICY_AND_THE_USE_OF_COOKIES',
  },
  {
    label: 'Często zadawane pytania (FAQ)',
    file: 'FAQ',
  },
];
